import type { App } from 'vue';
import PrimeVue from 'primevue/config';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Tooltip from 'primevue/tooltip';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import AutoComplete from 'primevue/autocomplete';
import ProgressSpinner from 'primevue/progressspinner';
import OverlayPanel from 'primevue/overlaypanel';
import Toast from 'primevue/toast';
import BlockUI from 'primevue/blockui';
import Image from 'primevue/image';

export default function primeVue(app: App<Element>) {
  app.use(PrimeVue);
  app.component('Button', Button);
  app.component('InputText', InputText);
  app.component('DataTable', DataTable);
  app.component('Column', Column);
  app.component('Dialog', Dialog);
  app.component('Calendar', Calendar);
  app.component('Checkbox', Checkbox);
  app.component('InputNumber', InputNumber);
  app.component('Dropdown', Dropdown);
  app.component('AutoComplete', AutoComplete);
  app.component('ProgressSpinner', ProgressSpinner);
  app.component('OverlayPanel', OverlayPanel);
  app.component('BlockUI', BlockUI)
  app.component('Toast', Toast);
  app.component('Image', Image);
  app.directive('tooltip', Tooltip);
}
