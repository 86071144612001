import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import 'primevue/resources/themes/aura-light-green/theme.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'
import App from './App.vue';
import { router } from './router';
import primeVue from './primeVue';
import ToastService from 'primevue/toastservice';
import * as Sentry from "@sentry/vue";

const app = createApp(App)

app.use(createPinia())
app.use(ToastService);
app.use(router)
primeVue(app);
Sentry.init({
    app,
    dsn: "https://1128d42b01a633a23c465cddb2431769@o4507658809442304.ingest.de.sentry.io/4507658812129360",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

app.mount('#app')
