import { ref, computed, reactive, onMounted, watch } from 'vue'
import { defineStore } from 'pinia'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { pickTwoActors } from '@/utils/commonFunctions'
import condensedData from '../assets/condensed-actors-list.json'

export const useDegreeStore = defineStore('connection', () => {
  let connections = reactive<Connection[]>([])
  let scores = reactive<string[]>([])
  let completed = ref(false)
  let completed_common_movies = ref([])
  let failed = ref(false)
  let isLoading = ref(false)
  let apiURL = import.meta.env.VITE_APP_API_URL
  const toast = useToast()
  const initDate = new Date()
  let year = initDate.getFullYear();
  let month = initDate.getMonth();
  let day = initDate.getDate();
  const selectedDate = ref(new Date(Date.UTC(year, month, day)))
  // Actors
  let actor1 = ref({})
  let actor2 = ref({})

  async function actorConnect(actor_id_1: number, entered_actor: Actor, actor_id_3: number) {
    isLoading.value = true

    const headers = {
      'Content-Type': 'application/json'
    }
    return axios
      .post(`${apiURL}/actorConnect`, { actor_id_1, actor_id_2: entered_actor['id'], actor_id_3 })
      .then((response) => {
        if (response.data['worked_together']) {
          connections.push({ ...entered_actor, common_movies: response.data['common_movies'] })
          if (response.data['completed']) {
            completed_common_movies.value = response.data['completed_movies']
            scores.push('Completed')
            completed.value = true
            toast.add({
              severity: 'success',
              summary: 'Well done!',
              detail: 'You completed the puzzle today.',
              life: 1500
            })
          } else {
            scores.push('Partial')
            if (scores.length > 4) {
              failed.value = true
            } else {
            toast.add({
              severity: 'success',
              summary: 'Connection Found',
              detail: 'Connection Found.',
              life: 1500
            })}
          }
        } else {
          scores.push('Wrong')
          toast.add({
            severity: 'error',
            summary: 'Incorrect!',
            detail: 'No Connection Found.',
            life: 1500
          })
          if (scores.length > 4) {
            failed.value = true
          }
        }
      })
      .catch((error) => {
        toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error. Please try again!',
          life: 1500
        })
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const loadLocalData = (date: Date) => {
    const key = `degreesGameState-${date.toLocaleDateString()}`
    const savedState = localStorage.getItem(key)
    if (savedState) {
      const gameState = JSON.parse(savedState)
      connections.length = 0
      gameState.connections.forEach(
        (connection: { name: string; id: number; profile_path: string; common_movies: any }) =>
          connections.push(connection)
      )

      scores.length = 0
      gameState.scores.forEach((score: string) => scores.push(score))

      completed.value = gameState.completed
      failed.value = gameState.failed
      completed_common_movies.value = gameState.completed_common_movies
    } else {
      connections.length = 0
      scores.length = 0

      completed.value = false
      failed.value = false
    }
  }

  const loadGameData = async (date: Date) => {
    pickTwoActors(date.toLocaleDateString(), 380, (actors: any[]) => {
      actor1.value = condensedData[actors[0]]
      actor2.value = condensedData[actors[1]]
    })
  }

  const setDate = (date: Date) => {
    selectedDate.value = date
  }

  onMounted(() => {
    loadLocalData(selectedDate.value)
    loadGameData(selectedDate.value)
  })
  const saveState = () => {
    const updatedState: GameState = {
      connections: connections,
      scores: scores,
      completed: completed.value,
      failed: failed.value,
      completed_common_movies: completed_common_movies.value
    }
    localStorage.setItem(
      `degreesGameState-${selectedDate.value.toLocaleDateString()}`,
      JSON.stringify(updatedState)
    )
  }
  // Watch for changes to selectedDate and update localData
  watch(selectedDate, (newDate) => {
    if (newDate) {
      loadLocalData(newDate)
      loadGameData(newDate)
    }
  })

  watch(connections, () => {
    saveState()
  })
  watch(scores, () => {
    saveState()
  })
  watch(completed, () => {
    saveState()
  })
  watch(failed, () => {
    saveState()
  })
  return {
    isLoading,
    connections,
    scores,
    completed,
    failed,
    actorConnect,
    setDate,
    selectedDate,
    actor1,
    actor2,
    completed_common_movies
  }
})

interface GameState {
  connections: Connection[]
  scores: string[]
  completed: boolean
  failed: boolean
  completed_common_movies: any[]
}
