import suggestions from '@/assets/actors-list.json'

const getRandomInt = (seed: number, max: number): number => {
  const a = 1664525
  const c = 1013904223
  const m = 2 ** 32
  seed = (a * seed + c) % m
  return seed % max
}
const pickTwoActors = (date: string, totalActors: number, callback: (actors: [number, number]) => void): void => {
  // Function to hash a string into a 32-bit integer
  const simpleHash = (str: string): number => {
    let hash = 0, i, chr;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash);
  }

  // Class to generate random numbers based on a seed
  class SeededRandom {
    private seed: number;

    constructor(seed: number) {
      this.seed = seed;
    }

    next(): number {
      const x = Math.sin(this.seed++) * 10000;
      return x - Math.floor(x);
    }

    nextInt(min: number, max: number): number {
      return Math.floor(this.next() * (max - min + 1)) + min;
    }
  }

  const baseHash = simpleHash(date);

  const rng1 = new SeededRandom(baseHash);
  const rng2 = new SeededRandom(baseHash + 1); // Ensure a different sequence

  let actor1 = rng1.nextInt(0, totalActors - 1);
  let actor2: number;

  do {
    actor2 = rng2.nextInt(0, totalActors - 1);
  } while (actor1 === actor2);

  callback([actor1, actor2]);
}

const search = (event: EventType, callback: (actors: any[]) => void): void => {
  let filteredActors = []
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredActors = [suggestions]
    } else {
      filteredActors = suggestions
        .filter((actor: any) => {
          return actor.name.toLowerCase().includes(event.query.toLowerCase())
        })
        .slice(0, 20)
    }
    callback(filteredActors)
  }, 250)
}

const convertAnsToEmoji = (score: string): string => {
  return score == 'Completed' ? '🟩' : score == 'Partial' ? '🟨' : score == 'Wrong' ? '🟥' : ''
}
export { getRandomInt, search, convertAnsToEmoji, pickTwoActors }
