<template>
  <div id="app" class="shadow-element" style="padding: 10px; padding-top: 0px">
    <BlockUI :blocked="isLoading">
      <div class="logo" style="margin-bottom: 50px">
        <div style="text-align: center">
          <span>Degrees</span>
          <Calendar
            v-model="date"
            showIcon
            :maxDate="new Date()"
            :minDate="new Date('01 01 2024')"
          />
          <span class="pi pi-question" style="cursor: pointer" @click="openRulesDialog"></span>
        </div>
        <hr />
        <div style="display: flex; justify-content: center">
          <i class="pi pi-angle-left" style="cursor: pointer" @click="goToPreviousDay"></i>
          <span
            style="
              text-align: center;
              display: block;
              font-size: small;
              margin-left: 5px;
              margin-right: 5px;
            "
            >{{
              date.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'long',
                year: 'numeric'
              })
            }}</span
          >
          <i class="pi pi-angle-right" style="cursor: pointer" @click="goToNextDay"></i>
        </div>
        <hr style="width: 180px" />
      </div>
      <div class="content-wrapper">
        <HomeView />
      </div>
    </BlockUI>
    <Toast position="bottom-center">
      <template #message="slotProps">
        <div class="flex flex-column align-items-start" style="flex: 1">
          <div class="font-medium text-lg my-3 text-900">{{ slotProps.message.detail }}</div>
        </div>
      </template>
    </Toast>

    <Dialog v-model:visible="rulesVisible" modal header="Degrees Rules" :style="{ width: '70%' }">
      <pre id="rules" style="white-space: preserve-breaks; font-family: inherit">
        Objective: Connect two actors through other actors who have worked with them, within 5 tries. 
This game is based on the Six Degrees of Kevin Bacon, which suggests that any two actors can be linked through their film roles in six steps or fewer.

How to Play:

    Start: The game shows two actors.
    Enter Actor: Type an actor's name who has worked with the first actor.
    Check Connections:
        If the actor worked with the first actor, the game shows their connection.
        If the actor also worked with the second actor, you win.
    Continue Linking: If the link is incomplete, enter new actors who have worked with the previously entered actor.
    Win: Link the two actors within 5 tries.

Example:

    Actors: Tom Hanks and Jennifer Lawrence
    Entry 1: Leonardo DiCaprio (worked with Tom Hanks in "Catch Me If You Can")
    Entry 2: Kate Winslet (worked with Leonardo DiCaprio in "Titanic")
    Entry 3: Bradley Cooper (worked with Kate Winslet in "American Hustle")
    Result: Bradley Cooper worked with Jennifer Lawrence in "Silver Linings Playbook"

  </pre
      >
    </Dialog>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import HomeView from './views/HomeView.vue'
import { name } from '../package.json'
import { useDegreeStore } from './stores/connection'

const degreeStore = useDegreeStore()
const date = ref(new Date())
const appName = ref(name)
const isLoading = computed(() => degreeStore.isLoading)
let rulesVisible = ref(false)
const openRulesDialog = () => {
  rulesVisible.value = true
}
const goToPreviousDay = () => {
  // Create a Date object for January 1, 2024
const targetDate = new Date(Date.UTC(2024, 0, 1)); // Note: Months are zero-indexed, so 0 represents January

// Check if the current date matches January 1, 2024
const isJanuaryFirst2024 = date.value.getFullYear() === targetDate.getFullYear() &&
                           date.value.getMonth() === targetDate.getMonth() &&
                           date.value.getDate() === targetDate.getDate();
  if (!isJanuaryFirst2024) {
    date.value.setDate(date.value.getDate() - 1)
    date.value = new Date(Date.UTC(date.value.getFullYear(), date.value.getMonth(), date.value.getDate()));
  }
}
const goToNextDay = () => {
  const today = new Date();
  const todayYr = today.getFullYear();
  const todayMonth = today.getMonth();
  const todayDay = today.getDate();
  const targetDate = new Date(Date.UTC(todayYr, todayMonth, todayDay)); // Note: Months are zero-indexed, so 0 represents January
// Check if the current date matches January 1, 2024
const isToday = date.value.getFullYear() === targetDate.getFullYear() &&
                           date.value.getMonth() === targetDate.getMonth() &&
                           date.value.getDate() === targetDate.getDate();
  if (!isToday) {
    date.value.setDate(date.value.getDate() + 1);
    date.value = new Date(Date.UTC(date.value.getFullYear(), date.value.getMonth(), date.value.getDate()));
  }
}
watch(date, (newDate) => {
  if (newDate) {
    let year = newDate.getFullYear();
    let month = newDate.getMonth();
    let day = newDate.getDate();
    degreeStore.setDate(new Date(Date.UTC(year, month, day)));
  }
})
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
html {
  margin: 0px !important;
  background-color: rgb(19, 30, 54);
  color: white;
  min-height: 95%;
}
body {
  padding: 10px;
  padding-top: 10px;
  min-height: 100%;
  max-width: 800px;
  margin: auto !important;
  display: grid;
}
.content-wrapper {
  display: flex;
  flex: 1;
}

.logo {
  margin: auto;
  margin-top: 20px !important;
  margin-left: 10%;
  margin-right: 10%;
}

.logo img {
  width: 120px !important;
  margin: auto;
  display: block;
}
main {
  flex: 1;
  margin: 40px;
  margin-top: 100px;
}
</style>
