<template>
  <div style="margin: auto">
    <div class="grid" style="margin: auto">
      <Dialog :closable="false" :visible="isLoading">
        <ProgressSpinner v-if="isLoading" />
      </Dialog>
      <div style="min-width: 280px">
        <!-- Actor 1 Image/Name -->
        <div v-if="actor1" style="display: flex">
          <Image width="60px" :src="'https://image.tmdb.org/t/p/w185/' + actor1.profile_path" preview class="shadow-element" />
          <span style="margin: auto; color: white" class="shadow-element">{{ actor1.name }}</span>
        </div>
        <div v-if="conns.length > 0" class="col-12 conn-element">
          <!-- First Link -->
          <span
            :class="[ICONS.LINK, 'link', 'shadow-element']"
            @click="toggleOverlay($event, 0)"
            style="font-size: x-small; cursor: pointer"
          />
        </div>
        <!-- Each Connection -->
        <div class="grid" style="margin-top: 5px">
          <div class="col-12 conn-element" v-for="(conn, index) in conns" :key="index">
            <div
              class="col-12 namecard shadow-element"
              style="background-color: #80808030; border-radius: 20px"
            >
              <span>{{ conn.name }}</span>
            </div>
            <div class="col-12 conn-element">
              <span
                :class="[ICONS.LINK, 'link', 'shadow-element']"
                @click="toggleOverlay($event, index + 1)"
                :style="
                  'font-size: x-small;' + (conns[index + 1] || completed ? ' cursor: pointer;' : '')
                "
              />
              <!-- + conns.length >= index ? 'cursor: pointer' : '' -->
            </div>
          </div>
        </div>
        <!-- When 0 connections added -->
        <div class="col-12 conn-element" v-if="!completed && !failed">
          <div v-if="conns.length <= 0" class="col-12 conn-element">
            <span :class="[ICONS.LINK, 'link', 'shadow-element']" style="font-size: x-small" />
          </div>

          <div class="col-12 conn-element">
            <span
              :class="[ICONS.QUESTION, 'namecard', 'shadow-element']"
              style="font-size: x-small"
            />
          </div>

          <div class="col-12 conn-element">
            <span :class="[ICONS.LINK, 'link', 'shadow-element']" style="font-size: x-small" />
          </div>
        </div>
        <!-- Actor 2 Image/Name -->
        <div v-if="actor2" style="display: flex; margin-bottom: 25px; flex-direction: row-reverse">
          <Image width="60px" :src="'https://image.tmdb.org/t/p/w185/' + actor2.profile_path" preview class="shadow-element" />
          <span style="margin: auto; color: white" class="shadow-element">{{ actor2.name }}</span>
        </div>
        <!-- Input Box -->
        <div
          class="col-12 shadow-element"
          v-if="!(completed || failed)"
          style="
            text-align: center;
            margin-bottom: 25px;
            background-color: #80808030;
            padding: 15px;
            border-radius: 20px;
          "
        >
          <div class="grid" padding="2%">
          <div class="col-10">
            <AutoComplete
              v-model="enteredActor"
              optionLabel="name"
              optionValue="id"
              placeholder="Enter Actor . . ."
              :suggestions="filteredActors"
              @complete="filterActors"
              class="shadow-element"
            />
          </div>
          <div class="col-2" >
            <Button
              :class="[ICONS.GO, 'shadow-element']"
              style="margin: 0px; padding: 0px; height: 38px; margin-left: 10px; padding-left: 7px"
              @click="addConnection"
            />
          </div>
          </div>
        </div>
        <div
          class="shadow-element"
          style="text-align: center; margin: auto; margin-bottom: 10px; padding: 10px"
        >
          <span v-if="completed">Well done, you found a link!</span>
          <span v-else-if="failed">You did not find a link. Better luck next time!</span>
          <span style="text-align: center; margin: auto" v-else
            >{{ 5 - scores.length }} attempts remaining</span
          >
        </div>
        <!-- Indicators -->
        <div
          v-if="scores.length > 0"
          style="
            text-align: center;
            margin-bottom: 50px;
            background-color: #80808030;
            padding: 10px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
          "
          class="shadow-element"
        >
          <div style="margin: 10px">
            <span v-for="(score, index) in scores" :key="index">{{
              convertAnsToEmoji(score)
            }}</span>
          </div>
          <Button
            v-if="completed || failed"
            label="Share"
            icon="pi pi-share-alt"
            style="width: max-content; margin: auto"
            @click="copyResultsToClipboard"
          />
        </div>
      </div>
    </div>
    <OverlayPanel ref="op">
      <div>
        <span>Worked together on </span>
        <div v-for="(mov, index) in common_movies" :key="index">
          <span>{{ ' - ' + mov.title + '\n' }}</span>
          <Button
            :icon="ICONS.EXTERNAL_LINK"
            style="
              background-color: transparent;
              border-color: transparent;
              height: 15px;
              color: black;
            "
            @click="openInNewTab('https://www.letterboxd.com/tmdb/' + mov.id)"
          >
          </Button>
        </div>
      </div>
    </OverlayPanel>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useDegreeStore } from '../stores/connection'
import { search, convertAnsToEmoji } from '@/utils/commonFunctions'
import { ICONS } from '@/types/icons.ts'
import { useToast } from 'primevue/usetoast'
const toast = useToast()
let filteredActors = ref()
const enteredActor = ref()
const degreeStore = useDegreeStore()

let common_movies = ref({})
// Computed variables
const conns = computed(() => degreeStore.connections)
const scores = computed(() => degreeStore.scores)
const completed = computed(() => degreeStore.completed)
const failed = computed(() => degreeStore.failed)
const selectedDate = computed(() => degreeStore.selectedDate)
const isLoading = computed(() => degreeStore.isLoading)
const actor1 = computed(() => degreeStore.actor1)
const actor2 = computed(() => degreeStore.actor2)
const completed_common_movies = computed(() => degreeStore.completed_common_movies)
let url = import.meta.env.VITE_APP_URL
// Add connection if correct
const addConnection = async () => {
  if (!isLoading.value) {
    if (enteredActor && enteredActor.value && enteredActor.value['id']) {
      if (
        ![...degreeStore.connections.map((x) => x.id), actor1.id, actor2.id].includes(
          enteredActor.value['id']
        )
      ) {
        if (degreeStore.connections.length > 0) {
          await degreeStore.actorConnect(
            degreeStore.connections[degreeStore.connections.length - 1].id,
            enteredActor.value,
            actor2.value.id
          )
        } else {
          await degreeStore.actorConnect(actor1.value.id, enteredActor.value, actor2.value.id)
        }
      }
      enteredActor.value = null
    } else {
      toast.add({
        severity: 'error',
        summary: 'Actor Not Found',
        detail: 'Actor Not Found',
        life: 1500
      })
    }
  }
}
const filterActors = async (event) => {
  search(event, (actors) => {
    filteredActors.value = actors
  })
}
const openInNewTab = (url) => {
  window.open(url, '_blank')
}
const copyResultsToClipboard = async () => {
  const res = `Degrees ${selectedDate.value.toISOString().split('T')[0]}    \n${scores.value.map((x) => convertAnsToEmoji(x)).join('')}    \n${url}`
  try {
    await navigator.clipboard.writeText(res)
    toast.add({
      severity: 'info',
      summary: 'Copied',
      detail: 'Copied Results to Clipboard',
      life: 1000
    })
  } catch {}
}

const op = ref(null)
const toggleOverlay = (event, index) => {
  //get
  if (completed.value && index == conns.value.length) {
    common_movies = completed_common_movies.value
    op.value.toggle(event)
    //use completed_common_movies
  } else if (conns.value.length > index) {
    common_movies = conns.value[index].common_movies
    op.value.toggle(event)
  }
}
</script>
<style scoped>
.link {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0.6em;
}
.namecard {
  padding: 0.6em;
  margin: 0;
}
.conn-element {
  display: block;
  text-align: center;
  padding: 0;
}
</style>
